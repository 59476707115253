<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit" 
                    v-model:expandedRows="expandedRows">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column :expander="true" :headerStyle="{'width': '3rem'}" />
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="name" header="Name">
                        <template #body="slotProps">
                            {{slotProps.data.name}}
                        </template>
                    </Column>
                    <Column field="kode_outlet" header="Kode Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.kode_outlet}}
                        </template>
                    </Column>
                    <Column field="name_outlet" header="Nama Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.name_outlet}}
                        </template>
                    </Column>
                    <Column field="date" header="Tanggal">
                        <template #body="slotProps">
                            {{slotProps.data.date}}
                        </template>
                    </Column>
                    <Column field="is_approved_label" header="Status Approval">
                        <template #body="slotProps">
                            {{slotProps.data.is_approved_label}}
                        </template>
                    </Column>
					<Column header="Action" headerStyle="min-width:10rem;">
						<template #body="slotProps">
                            <div v-if='slotProps.data.is_approved_label == "Pending"'>
                                <div v-if="permission.includes('update')">
                                    <Button :loading="slotProps.data.isLoadingY"  label="Approve" class="p-button p-button-success mr-2 my-1 inline-block" @click="approvalItem(slotProps.data,'y')" />
                                    <Button :loading="slotProps.data.isLoadingN" label="Reject" class="p-button p-button-danger mr-2 my-1 inline-block" @click="approvalItem(slotProps.data,'n')" />
                                </div>
                            </div>
						</template>
					</Column>
                     <template #expansion="slotProps">
                        <div class="orders-subtable">
                            <h5>Detail  {{slotProps.data.username}}</h5>
                            <DataTable :value="slotProps.data.produk">
                                <Column field="tipe_sales" header="Tipe Sales">
                                    <template #body="slotProps">
                                        {{slotProps.data.tipe_sales}}
                                    </template>
                                </Column>
                                <Column field="no_surat_promo" header="Nomor Surat">
                                    <template #body="slotProps">
                                        {{slotProps.data.no_surat_promo}}
                                    </template>
                                </Column>
                                <Column field="url_photo_surat" header="Photo Surat">
                                    <template #body="slotProps">
                                        <div v-if="slotProps.data.url_photo_surat">
                                            <Image :src="slotProps.data.url_photo_surat" :alt="slotProps.data.url_photo_surat" width="100" preview />
                                        </div>
                                        <div v-else>
                                            <Image src="no_image.jpg" alt="No Image" width="100" preview />
                                        </div>
                                    </template>
                                </Column>
                                <Column field="kode_produk" header="Kode Produk">
                                    <template #body="slotProps">
                                        {{slotProps.data.kode_produk}}
                                    </template>
                                </Column>
                                <Column field="name_produk" header="Nama Produk">
                                    <template #body="slotProps">
                                        {{slotProps.data.name_produk}}
                                    </template>
                                </Column>
                                <Column field="harga" header="Harga">
                                    <template #body="slotProps">
                                        {{slotProps.data.harga}}
                                    </template>
                                </Column>
                                <Column field="qty" header="QTY">
                                    <template #body="slotProps">
                                        {{slotProps.data.qty}}
                                    </template>
                                </Column>
                                <Column field="diskon" header="Diskon">
                                    <template #body="slotProps">
                                        {{slotProps.data.diskon}}
                                    </template>
                                </Column>
                                <Column field="free_goods" header="Free Goods">
                                    <template #body="slotProps">
                                        {{slotProps.data.free_goods}}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/master/approval-order/Filter.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Error': Error,
		'Filter': Filter,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            // approve,reject
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,

            //expand datatable
            expandedRows: [],
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors','errorEdit']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // APPROVE
		approvalItem(item,status) {

            if(status == 'y'){
                item.isLoadingY = true;
            }else{
                item.isLoadingN = true;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/approval-order/approval',
                data: {
                    "ordermain_id" : item.ordermain_id,
                    "status" : status,
                },
            })
            .then((res) => {

                if(status == 'y'){
                    item.isLoadingY = false;
                }else{
                    item.isLoadingN = false;
                }

                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Updated', life: 3000,});
                    this.$store.commit('setErrors', {});
                    // this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                if(status == 'y'){
                    item.isLoadingY = false;
                }else{
                    item.isLoadingN = false;
                }
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Updated', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/approval-order',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "tipe_sales" : this.$refs.filter.filters.tipe_sales,
                    "status" : this.$refs.filter.filters.status,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/approval-order/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "tipe_sales" : this.$refs.filter.filters.tipe_sales,
                "status" : this.$refs.filter.filters.status,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Approval Order Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>